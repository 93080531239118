<template>
  <div class="body-2 text-lg-subtitle-1 progress-stepper pa-0">
    <v-stepper
      v-model="currentStep"
      :class="wrapperColor"
    >
      <v-stepper-header
        class="stepper-header justify-center justify-sm-space-between flex-sm-nowrap align-center
        position-relative col-sm-10 mx-sm-auto text-uppercase px-0"
      >
        <v-stepper-step
          class="py-2 stepper-item px-sm-0 position-relative"
          step="1"
        >
          <div
            :class="['ml-3 ml-sm-0 mt-sm-3', textColor]"
          >
            Особисті дані
          </div>
        </v-stepper-step>

        <v-divider
          class="d-none d-sm-block divider-left divider stepperDivider position-absolute pa-0"
        />

        <v-stepper-step
          class="stepper-item py-2 px-sm-0 mt-5 mt-sm-0"
          step="2"
        >
          <div
            :class="['ml-3 ml-sm-0 mt-sm-3', textColor]"
          >
            Спосіб отримання
          </div>
        </v-stepper-step>

        <v-divider
          class="d-none d-sm-block divider-right divider stepperDivider position-absolute pa-0"
        />

        <v-stepper-step
          class="py-2 px-sm-0 mt-5 mt-sm-0 stepper-item"
          step="3"
          color="primary"
        >
          <div
            :class="['ml-3 ml-sm-0 mt-sm-3', textColor]"
          >
            Подача заявки
          </div>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    wrapperColor: {
      type: String,
      default: 'background lighten-1',
    },
    textColor: {
      type: String,
      default: 'text--text',
    },
  },
  computed: {
    currentStep() {
      return this.$store.state.cashForm.currentStep;
    },
  },
};
</script>

<style lang="scss" scoped>

  .v-stepper__header {
    box-shadow: none !important;

    $screenWidths: ('600px': 500px, '960px': 600px, '1264px': 700px);

    @each $screen, $stepperWidth in $screenWidths {
      @media screen and (min-width: $screen) {
        width: $stepperWidth;
      }
    }
  }

  .progress-stepper {
    .v-stepper {
      box-shadow: none !important;
    }

    .v-stepper__step__step.primary {
      background-color: #36a935 !important;
    }
  }

  .stepper-item {
   @media screen and (max-width: 400px) {
     padding-left: 15px !important;
     padding-right: 10px !important;

     @media screen and (min-width: 370px) {
       padding-left: 20px !important;
     }
   }
  }

  .divider {
    top: 44px;
    width: 20%;
    padding: 0.5px !important;

    @media screen and (min-width: 960px) {
      width: 23%;
    }

    @media screen and (min-width: 1264px) {
      width: 27%;
    }

    &-left {
      left: 115px;

      @media screen and (min-width: 960px) {
        left: 118px;
      }
    }

    &-right {
      right: 120px;

      @media screen and (min-width: 960px) {
        right: 130px;
      }

      @media screen and (min-width: 1264px) {
        right: 136px;
      }
    }
  }
</style>
