<template>
  <v-scale-transition
    mode="out-in"
  >
    <div
      v-if="isAuthenticated"
      class="light-theme pb-10"
    >
      <notification
        v-if="errorNotification"
        type="error"
      >
        Щось пішло не так. Спробуйте ще раз
      </notification>

      <stepper
        wrapper-color="background"
      />

      <cards-list
        ref="cardSelection"
        :cards="clientCards"
        class="mt-5"
        @toggleAddingNewCard="onExistCardChoise"
        @onFormSend="blockPreloaderIsVisible = !blockPreloaderIsVisible"
        @onServerError="blockPreloaderIsVisible = !blockPreloaderIsVisible; toggleErrorMessage();"
      />

      <v-row
        no-gutters
        class="col-9 col-sm-12 col-lg-8 col-xl-5 mx-auto mt-n2 mt-3 pa-0 flex-column justify-center"
      >
        <v-row
          no-gutters
          class="position-relative"
        >
          <block-preloader
            v-if="blockPreloaderIsVisible"
            class="mb-2"
          />
          <buttons-block
            :class="{ 'visibility-hidden': blockPreloaderIsVisible }"
            hide-clear-button="true"
            @continueButtonClick="submitForm"
          />
        </v-row>
      </v-row>

      <v-row
        no-gutters
        class="justify-center"
      >
        <p
          class="text-center success--text hover-link cursor-pointer mr-sm-4 mt-3"
          @click="toggleAddingBankCard"
        >
          Додати нову картку
        </p>
      </v-row>

      <v-expand-transition>
        <link-bank-card-form
          v-if="isAddingNewCard"
          class="col-sm-10 col-md-9 col-lg-7 col-xl-6 mx-auto pa-0 mt-8"
        />
      </v-expand-transition>
    </div>
  </v-scale-transition>
</template>

<script>
import Stepper from '@/components/common/Stepper';
import { mapActions, mapGetters } from 'vuex';
import CardsList from '@/views/link-bank-card/CardsList';
import ButtonsBlock from '@/components/common/ButtonsBlock';
import LinkBankCardForm from '@/views/link-bank-card/LinkBankCardForm';
import Notification from '@/components/common/Notification';
import blockPreloaderMixin from '@/mixins/blockPreloaderMixin';
import errorNotificationMixin from '@/mixins/errorNotificationMixin';
import BlockPreloader from '@/components/common/BlockPreloader';
import pageLoaderMixin from '../../mixins/pageLoaderMixin';

export default {
  name: 'LinkingBankCard',
  title: 'Прив\'язка картки',
  components: {
    BlockPreloader,
    LinkBankCardForm,
    ButtonsBlock,
    CardsList,
    Stepper,
    Notification,
  },
  mixins: [blockPreloaderMixin, errorNotificationMixin, pageLoaderMixin],
  data() {
    return {
      cardArr: [],
      selectedCardId: null,
      selectedCard: {},
      currentProductCode: 'cache_online',
      isAddingNewCard: false,
      testCards: [
        {
          isDefault: true,
          cardMask: '**** **** **** 8848',
          ifValid: true,
        },
        {
          isDefault: false,
          cardMask: '**** **** **** 8888',
          ifValid: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    ...mapGetters('bankCards', [
      'storageCards',
    ]),
    bankCardAddedSuccessfully: {
      get() {
        return this.$store.state.cashForm.bankCardAddedSuccessfully;
      },
      set(booleanValue) {
        this.$store.commit('cashForm/CHANGE_BOOLEAN_STATE', {
          elementName: 'bankCardAddedSuccessfully',
          booleanValue,
        });
      },
    },
    clientCards: {
      get() {
        return this.$store.state.bankCards.clientCards;
      },
      set(value) {
        this.$store.commit('bankCards/UPDATE_STATE', {
          key: 'clientCards',
          value,
        });
      },
    },
  },
  beforeMount() {
    this.updateStep(2);
    this.getReferenceBookStore('DICT_CREDIT_PAYMENT_TYPE');
    this.getCardsStore() /* запрос за картами пользователями */
      .then(() => {
        this.hidePagePreloader();
      })
      .catch((err) => console.log(err));
    this.getStateStore(this.currentProductCode);
  },
  mounted() {
    const interval = setInterval(() => {
      if (!this.bankCardAddedSuccessfully && this.isAuthenticated) {
        this.toggleErrorMessage();
        this.bankCardAddedSuccessfully = true;
        clearInterval(interval);
      }
    }, 3000);
  },
  beforeDestroy() {
    // this.clientCards = [];
    this.$store.commit('REMOVE_ITEM_FROM_REFERENCE_BOOK', {
      itemName: 'dict_credit_payment_type',
    });
  },
  methods: {
    ...mapActions('bankCards', [
      'getCardsStore',
      'getCardsFromLocalStorage',
      'setCardsIdsToLocalStorage',
    ]),
    ...mapActions('cashForm', [
      'updateStep',
      'getStateStore',
    ]),
    ...mapActions(['getReferenceBookStore']),
    toggleAddingBankCard() {
      this.isAddingNewCard = !this.isAddingNewCard;
      this.scrollToTop();
    },
    onExistCardChoise() {
      this.isAddingNewCard = false;
    },
    submitForm() {
      this.$refs.cardSelection.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-list-item {
    min-height: auto !important;
  }
</style>
