import store from '../store';

export default class ActiveCashOnlineOrderService {
  // eslint-disable-next-line consistent-return
  static async getOrder() {
    try {
      const productCode = 'cache_online';
      const activeOrders = await store.dispatch('cashForm/getActiveOrderStore');

      if (!activeOrders) {
        return false;
      }

      return activeOrders.find((item) => item.product === productCode);
    } catch (error) {
      console.error(error);
    }
  }
}
