<template>
  <v-row
    no-gutters
    class="justify-center"
  >
    <v-subheader class="text--text height-auto text-h5 text-center d-block mb-1">
      {{ !creditPaymentType || creditPaymentType === 2 ?
      'Оберіть спосіб отримання коштів' : 'Оберіть одну з Ваших карт'}}
      <v-fade-transition duration="1000">
        <template v-if="creditPaymentType === 1">
          <span>
            або
            <span
              class="ml-1 success--text hover-link cursor-pointer"
              @click="$emit('toggleAddingNewCard')"
            >додайте нову</span>
          </span>
        </template>
      </v-fade-transition>
    </v-subheader>

    <validation-observer
      ref="paymentTypeObserver"
      class="col-12"
    >
      <validation-provider
        v-slot="{ errors, validated }"
        rules="checkboxRequired"
      >
        <v-radio-group
          v-model="creditPaymentType"
          class="d-flex col-12 pa-0 gg"
          @change="clientCard = 0; toggleError(false)"
        >
          <v-row
            no-gutters
            class="justify-center"
          >
            <v-col
              v-for="(paymentType, index) of referenceBooks('dict_credit_payment_type')"
              :key="index"
              :class="['pa-0 d-flex align-center col-auto', { 'ml-10': index === 1 }]"
            >
              <v-radio
                :id="`${paymentType.name}${paymentType.silverId}`"
                color="success"
                :error="!creditPaymentType && validated"
                :success="creditPaymentType && validated"
                :value="paymentType.silverId"
                :dark="$vuetify.theme.dark"
                class="ma-0 "
                :disabled="paymentType.silverId === 2"
              />
              <label
                :for="`${paymentType.name}${paymentType.silverId}`"
                class="ma-0 text--text"
              >
                {{ paymentType.silverId === 1 ? "Банківська картка" : paymentType.value }}
                <span class="newColor" v-if='paymentType.silverId === 2'>
                  (В розробці)
                </span>
              </label>
            </v-col>
          </v-row>
          <span
            v-show="errors.length > 0"
            class="error-msg error--text v-messages text-center mt-2"
          >
            {{ errors[0] }}
          </span>
        </v-radio-group>
      </validation-provider>
    </validation-observer>
    <v-row
      v-if="creditPaymentType === 1"
      no-gutters
      class="col-10 col-sm-5 col-md-4 col-lg-3 mx-auto mb-3"
    >
      <validation-observer
        ref="cardsObserver"
        class="col-12"
      >
        <validation-provider
          v-if="cards.length > 0"
          v-slot="{ errors, validated }"
          rules="checkboxRequired"
        >
          <v-radio-group
            v-model="clientCard"
            class="d-flex pa-0 mt-3 col-12"
          >
            <v-divider
              class="cardDarkGray lighten-3 mb-5"
            />
            <v-col
              v-for="(card, index) of cards"
              :key="index"
              class="pa-0 d-flex align-center col-auto"
            >
              <v-radio
                :id="`${card.silverId}`"
                color="success"
                :value="card.silverId"
                :dark="$vuetify.theme.dark"
                :error="!clientCard && validated"
                :success="clientCard && validated"
                class="ma-0"
                @click.prevent="onBankCardClicked"
              />
              <label
                :for="`${card.silverId}`"
                class="ma-0 text--text--text"
              >
                {{ card.cardMask }}
              </label>
            </v-col>
          </v-radio-group>
          <span class="error-msg error--text v-messages pl-8 mt-n5 position-absolute">
            {{ errors[0] }}
          </span>
        </validation-provider>
      </validation-observer>

      <v-row
        v-if="cards.length <= 0"
        no-gutters
        class="col-12 pa-0 flex-column"
      >
        <v-divider
          class="cardDarkGray lighten-3 mb-3"
        />
        <p class="text--text text-center">
          Ви ще не додали жодної картки
        </p>

        <p
          v-if="dontHaveBankCard"
          class="error--text subtitle-2 text-center mt-n3"
        >
          Додайте нову карту або виберіть спосіб отримання "Нова пошта"
        </p>
      </v-row>
    </v-row>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import getActiveOrderIdMixin from '@/mixins/getActiveOrderIdMixin';
import RouteNamesService from '@/services/route-names-service';
import ActiveCashOnlineOrderService from '../../services/active-cash-online-order-service';
import OrderStatusService from '../../services/order-status-service';

export default {
  name: 'CardSelection',
  mixins: [getActiveOrderIdMixin],
  props: {
    cards: {
      type: Array,
    },
  },
  data() {
    return {
      dontHaveBankCard: false,
    };
  },
  computed: {
    ...mapGetters([
      'referenceBooks',
    ]),
    clientCard: {
      get() {
        return this.$store.state.cashForm.bankCard.clientCard;
      },
      set(value) {
        this.$store.commit('cashForm/UPDATE_FORM_DATA', {
          parentObjName: 'bankCard',
          attribute: 'clientCard',
          value,
        });
      },
    },
    creditPaymentType: {
      get() {
        return this.$store.state.cashForm.creditData.creditPaymentType;
      },
      set(value) {
        this.$store.commit('cashForm/UPDATE_FORM_DATA', {
          parentObjName: 'creditData',
          attribute: 'creditPaymentType',
          value,
        });
      },
    },
    isIpayPaymentType() {
      return this.creditPaymentType === 1;
    },
    isNpPaymentType() {
      return this.creditPaymentType === 2;
    },
  },
  methods: {
    ...mapActions('cashForm', [
      'updateExistingOrderStore',
    ]),
    onBankCardClicked() {
      this.$emit('toggleAddingNewCard');
    },
    getObjectToSend() {
      return {
        personalData: this.$store.state.cashForm.personalData,
        creditData: this.$store.state.cashForm.creditData,
        contactPerson: this.$store.state.cashForm.contactPerson,
        additionalInfo: this.$store.state.cashForm.additionalInfo,
        documentData: this.$store.state.cashForm.documentData,
        address: this.$store.state.cashForm.address,
        bankCard: this.$store.state.cashForm.bankCard,
      };
    },
    toggleError(bool = true) {
      this.dontHaveBankCard = bool;
    },
    async checkOrderActionTypeAndDoRedirect() {
      const activeOrder = await ActiveCashOnlineOrderService.getOrder();
      const actionType = activeOrder && activeOrder.actionType ? activeOrder.actionType.toLowerCase() : '';

      // eslint-disable-next-line no-unused-expressions
      actionType === OrderStatusService.processing
        ? await this.$router.push({ name: RouteNamesService.userCredits })
        : await this.$router.push({ name: RouteNamesService.creditCalculator });
    },
    async updateExistingOrder() {
      this.$emit('onFormSend');
      const obj = this.getObjectToSend();
      await this.updateExistingOrderStore({
        orderId: this.activeOrderId,
        objToSend: obj,
      });
      await this.checkOrderActionTypeAndDoRedirect();
      this.$emit('onFormSend');
    },
    // eslint-disable-next-line consistent-return
    async submitIpayPaymentType() {
      const cardsObserverIsValid = await this.$refs.cardsObserver.validate();

      if (this.isIpayPaymentType && this.cards.length <= 0) {
        this.toggleError();
      }

      if (!cardsObserverIsValid || this.clientCard === 0) {
        return false;
      }

      await this.updateExistingOrder();
    },
    // eslint-disable-next-line consistent-return
    async submitNpPaymentType() {
      const paymentTypeIsValid = await this.$refs.paymentTypeObserver.validate();

      if (!paymentTypeIsValid) {
        return false;
      }

      await this.updateExistingOrder();
    },
    // eslint-disable-next-line consistent-return
    async submit() {
      try {
        // eslint-disable-next-line no-unused-expressions
        this.isIpayPaymentType ? await this.submitIpayPaymentType()
          : await this.submitNpPaymentType();
      } catch (error) {
        this.$emit('onServerError');
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.newColor{
  font-size: 12px;
  font-weight: bold;
}
</style>
