<template>
  <div class="border-green border-radius-10 py-6 px-5 px-sm-16 box-shadow">
    <notification
      v-if="errorNotification"
      type="error"
    >
      Щось пішло не так. Спробуйте ще раз
    </notification>

    <v-card-title class="text--text justify-center text-center pa-0">
      <p class="text-h5 ma-0 font-weight-medium">
        Прив'язка картки
      </p>
      <p class="body-2 ma-0 col-12 pa-0 mt-1 mb-5">
        Картка має бути оформлена на Вас
      </p>
    </v-card-title>

    <validation-observer ref="observer">
      <v-row
        no-gutters
        justify="center"
        class="pa-0 mx-auto"
      >
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="Номер картки"
            rules="required|creditCard|creditCardMin:19|creditCardMax:19"
          >
            <v-text-field
              v-model="bankCardNumber"
              v-mask="'#### #### #### ####'"
              filled
              dense
              rounded
              outlined
              label="Номер картки*"
              color="cardDarkGray"
              item-color="text"
              :error-messages="errors"
              :menu-props="{ offsetY: false }"
              :success="valid"
              :dark="$vuetify.theme.dark"
              @change="validate"
            />
          </validation-provider>
        </v-col>

        <v-col
          cols="12"
          class="d-flex justify-center mt-n2 position-relative"
        >
          <block-preloader
            v-if="blockPreloaderIsVisible"
          />
          <v-btn
            :class="{ 'visibility-hidden': blockPreloaderIsVisible }"
            outlined
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            color="success"
            class="col-12 py-3 font-weight-bold"
            @click="addCard"
          >
            Додати картку
          </v-btn>
        </v-col>

        <v-col class="text--text">
          <p class="ma-0 col-12 pa-0 body-2 my-3">
            Зазначаючи реквізити електронного платіжного засобу (платіжної картки) на цьому вебсайті
            для здійснення будь-якої дії, як-от авторизації/ідентифікації особи,
            заповнення відповідної реєстраційної форми (реєстрації на вебсайті), додавання платіжної
            картки в особистому кабінеті (активація/реєстрація платіжної картки),
            для безготівкового перерахування коштів на рахунок, списання коштів із рахунку
            (погашення кредиту), позичальник усвідомлює,
            що ці реквізити зберігатимуться кредитодавцем або особою, з якою в кредитодавця є
            договірні відносини, та можуть використовуватися для списання коштів з рахунку
            для погашення вимог за договором про споживчий кредит. У разі настання такого списання
            позичальник має право в односторонньому порядку відмовитися або припинити
            списання коштів у визначеному договором порядку.
          </p>

          <p class="ma-0 col-12 pa-0 body-2 my-3">
            Будь ласка, додайте картку, яку хочете додати в свій профайл.
            Картка повинна відповідати наступним вимогам:
          </p>

          <v-list
            class="pa-0 ma-0"
            color="background"
          >
            <v-list-item class="pa-0 ma-0 align-center body-2 height-auto">
              <v-icon class="success--text">
                mdi-checkbox-marked-outline
              </v-icon>
              <span class="text--text ml-5">
                Це є Ваша картка
              </span>
            </v-list-item>
            <v-list-item class="pa-0 ma-0 align-start body-2 mt-3 height-auto">
              <v-icon class="success--text">
                mdi-checkbox-marked-outline
              </v-icon>
              <span class="text--text ml-5">
                Картка повинна бути активна та непрострочена
              </span>
            </v-list-item>
            <v-list-item class="pa-0 ma-0 align-start mt-3 body-2 height-auto">
              <v-icon class="success--text">
                mdi-checkbox-marked-outline
              </v-icon>
              <span class="text--text ml-5">
                За карткою мають бути дозволені операції в мережі Інтернет
              </span>
            </v-list-item>
            <v-list-item class="pa-0 ma-0 align-start mt-3 body-2 height-auto">
              <v-icon class="success--text">
                mdi-checkbox-marked-outline
              </v-icon>
              <span class="text--text ml-5">
                Під час перевірки картки на ній буде тимчасово заблоковано 1 грн.
              </span>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Notification from '@/components/common/Notification';
import blockPreloaderMixin from '@/mixins/blockPreloaderMixin';
import errorNotificationMixin from '@/mixins/errorNotificationMixin';
import BlockPreloader from '@/components/common/BlockPreloader';

export default {
  name: 'LinkBankCardForm',
  components: { BlockPreloader, Notification },
  mixins: [blockPreloaderMixin, errorNotificationMixin],
  props: {
    redirectAfterAddingCard: String,
  },
  data() {
    return {
      bankCardNumber: '',
    };
  },
  methods: {
    ...mapActions('bankCards', [
      'addClientsCardStore',
    ]),
    addCard() {
      this.$refs.observer.validate()
        .then((isValid) => {
          if (isValid) {
            this.toggleBlockPreloader(true);
            this.addClientsCardStore({
              cardNumber: this.bankCardNumber,
              redirect: this.redirectAfterAddingCard ?? this.$route.name,
            }).then((data) => {
              this.toggleBlockPreloader(false);
              if (!data) {
                this.toggleErrorMessage();
              }
            }).catch(() => {
              this.toggleBlockPreloader(false);
              this.toggleErrorMessage();
            });
          }
        }).catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>

</style>
